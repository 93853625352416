import React, {useContext, useState, useEffect} from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from "../components/button";
import CustomerContext from "../stores/customerContext";
import DonationLevel from "../components/donation-level";
import DonationFrequencies from "../stores/donationFrequencies";
import DonationTypes from "../stores/donationTypes";

export default function Frequency({type, displayName, Next}) {

    const {one_time, monthly, annually} = DonationFrequencies();
    const {amiType, leauxalType, rainAngelType} = DonationTypes();

    const {customer, updateContextValue} = useContext(CustomerContext);
    const [frequency, setFrequency] = useState(customer.frequency);
    const [showRecurring, setShowRecurring] = useState(true);

    const [style, setStyle] = useState('');

    useEffect(() => {
        ChooseFrequency(one_time);
    },[type]);

    function ChooseFrequency(newFrequency){
        setFrequency(newFrequency);
        customer.frequency = newFrequency;
        updateContextValue(customer);
    }

    function ChooseButtonClasses(frequencyOption){
        let className = '';

        switch(type){
            case amiType:
                className = `buttonAmi grow p-3 mx-6 text-center rounded-xl font-semibold text-[#FAFAFA] border-[#0082CA] border-[1px] hover:bg-[#00CFF5] hover:text-[#FAFAFA] ${ frequency === frequencyOption ? 'bg-[#0082CA]' : 'text-[#0082CA]'} `;
                break;
            case leauxalType:
                className = `buttonLeauxcal grow p-3 mx-6 text-center rounded-xl font-semibold text-[#FAFAFA] border-[#5F259F] border-[1px] hover:bg-[#CD00E1] hover:text-[#FAFAFA] ${ frequency === frequencyOption ? 'bg-[#5F259F]' : 'text-[#5F259F]'} `;
                break;
            case rainAngelType:
                className = `buttonRainAngel grow p-3 mx-6 text-center rounded-xl font-semibold text-[#FAFAFA] border-[#000000] border-[1px] hover:bg-[#4F4D52] hover:text-[#FAFAFA] ${ frequency === frequencyOption ? 'bg-[#000000]' : 'text-[#000000]'} `;
                break
            default:
                className = '';
                break;
        }
        return className;

    }

    return (
        <Box sx={{flexGrow: 4}}>        
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2 className=" text-[1.5rem]">Choose Your Donation Frequency</h2>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                    <div className="grid my-6">
                        <button onClick={() => ChooseFrequency(one_time)} className={ChooseButtonClasses(one_time)}>{one_time}</button>
                    </div>
                </Grid>
                { showRecurring === true ?
                    <>
                        <Grid item xs={4}>
                            <div className="grid my-6">
                            { type === amiType || type === leauxalType ? 
                                <button onClick={() => ChooseFrequency(monthly)} className= {ChooseButtonClasses(monthly)}>{monthly}</button> :
                                <> </>
                            }
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="grid my-6">
                            { type === amiType || type === rainAngelType ? 
                                <button onClick={() => ChooseFrequency(annually)} className= {ChooseButtonClasses(annually)}>{annually}</button> :
                                <> </>
                            }
                            </div>
                        </Grid>
                    </> : <></>
                }
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <DonationLevel type={type} frequency={frequency}></DonationLevel>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={6}>
                    <Button text={"Continue"} displayName={displayName} onClick={Next} />
                </Grid>
            </Grid>
        </Box>
    );
}