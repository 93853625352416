import React, {createContext, useState} from 'react';

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
    const [customer, setCustomer] = useState({value: ''});

    const updateContextValue = newValue => {
        setCustomer(newValue);
    };

    return (
        <CustomerContext.Provider value={{ customer, updateContextValue }}>
            {children}
        </CustomerContext.Provider>
    );
};

export default CustomerContext;