import Config from "../config";
export default async function fetchCreatePaymentIntentRecurring(customer){
    let data = {
        ...customer 
         };

    const response = await fetch(Config.api_url + "/stripe/payment-intent-recurring", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const subscription = await response.json();
    return subscription;
}