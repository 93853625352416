import "./App.css";
import React, { useEffect, useState } from "react";
import URIParameterHelper from "./utils/URIParameterHelper";
import DonationManager from "./components/donationManager";
import {CustomerProvider} from "./stores/customerContext";

export default function App() {
    let donationType = URIParameterHelper("donation");
    const [displayName, setDisplayName] = useState(null);
    const [displayType, setDisplayType] = useState(null);
    const customer = {};
  
    useEffect(() => {         
      switch(donationType)
      {
        case "Ami":{
            setDisplayName("Amis du Festival");
            setDisplayType("Ami");
          break;
        }
        case "Leauxcal":{
          setDisplayName("Festival Leauxcals");
          setDisplayType("Leauxcal");
        break;
        }
        case "RainAngel":{
          setDisplayName("Rain Angels' Circle");
          setDisplayType("Rain Angel");
        break;
      }
    }
  }, []);

  return(
    <CustomerProvider>
        <div className="md:text-center">
            <div className="gap-4 p-5"> 
                <DonationManager displayName={displayName} type={displayType} /> 
            </div>
        </div>
    </CustomerProvider>
    );
}