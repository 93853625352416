import Config from "../config";
export default async function fetchCancelPaymentIntent(paymentId){
    let data = {
        PaymentIntentId: paymentId
      };

    //const response = await fetch(Config.api_url + "/stripe/setup", {
    const response = await fetch(Config.api_url + "/stripe/cancel-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const subscription = await response.json();
    return subscription;
}