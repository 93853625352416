import { useEffect, useState, useContext, Fragment } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import Config from "../config";
import Button from "./button";
import CustomerContext from "../stores/customerContext";


export default function CheckoutForm({displayName}){
    const {customer, updateContextValue} = useContext(CustomerContext);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            console.error("Stripe.js has not yet loaded.");
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${Config.web_url}/`,
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log("result.error.message", result.error.message);
        }
        else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return(
        <Fragment>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <div className="grid my-6">
                <button
                    disabled={!stripe}
                    type="submit"
                >
                    <Button text={"Pay"} displayName={displayName} />
                </button>
                </div>
            </form>
        </Fragment>
    );
}