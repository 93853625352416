import React, {useEffect, useState, useContext, Fragment} from "react";
import fetchProducts from "../services/fetchProducts";
import Checkmark from "../checkmark.svg";
import CustomerContext from "../stores/customerContext";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DonationFrequencies from "../stores/donationFrequencies";
import DonationTypes from "../stores/donationTypes";

export default function DonationLevel({type, frequency}) {
    const [products, setProducts] = useState(null);
    const [options, setOptions] = useState([]);
    const {customer, updateContextValue} = useContext(CustomerContext);
    const [selectedLevel, setSelectedLevel] = useState(customer.selectedLevel || 0);

    // This is used for hover.  We want to remember the primary level that is selected
    // so we can go back to it after the mouse leaves the button.
    const [primaryLevel, setPrimaryLevel] = useState(null);
    const [hoveredLevel, setHoveredLevel] = useState(null);

    const {one_time, monthly, annually} = DonationFrequencies();
    const {amiType, leauxalType, rainAngelType} = DonationTypes();

    const [features, setFeatures] = useState([]);
    const [selectedProductName, setSelectedProductName] = useState('');

    // these are for checking if the frequency from stripe.  the frequencies are not
    // consistent
    let monthRegex = /mon/;
    let oneTimeRegex = /one/;
    let annuallyRegex = /ann/;

    useEffect(() => {       
        if(frequency != null){
            LoadProducts();
            setFeatures([]);
            if(customer.selectedProduct != null){
                SelectDonationLevel(customer.selectedProduct);
            }
        }
    }, [frequency]);

    /// When the products change, get the products to show based on the campaign selected and what frequency selected.
    useEffect(() => {
        if(products != null){
            var newTypes = products.filter((prod) => {
                    let passes = false;
                    if(frequency === one_time){
                        passes = prod.metadata.donor_campaign === type && oneTimeRegex.test(prod.metadata.Frequency.toLowerCase());
                    }
                    else if(frequency === monthly){
                        passes = prod.metadata.donor_campaign === type && monthRegex.test(prod.metadata.Frequency.toLowerCase());
                    }
                    else if(frequency === annually){
                        passes = prod.metadata.donor_campaign === type && annuallyRegex.test(prod.metadata.Frequency.toLowerCase());
                    }
                    else{
                        passes = false;
                    }
                    return passes;
                }
            );

            let foundOption = null;

            // Set Defaults
            if(type === amiType) { 
                switch(frequency){
                    case one_time:
                        foundOption = newTypes.find(e => parseFloat(sortMoneyOrder(e.name)) == 50);
                        break;
                    case monthly:
                        foundOption = newTypes.find(e => parseFloat(sortMoneyOrder(e.name)) == 20);
                        break;
                    case annually:
                        foundOption = newTypes.find(e => parseFloat(sortMoneyOrder(e.name)) == 50);
                        break;
                }
            }
            if(type === leauxalType) { 
                switch(frequency){
                    case one_time:
                        foundOption = newTypes.find(e => parseFloat(sortMoneyOrder(e.name)) == 500);
                        break;
                    case monthly:
                        foundOption = newTypes.find(e => parseFloat(sortMoneyOrder(e.name)) == 50);
                        break;
                    case annually:
                        foundOption = newTypes.find(e => parseFloat(sortMoneyOrder(e.name)) == 500);
                        break;
                }
            }
            if(type === rainAngelType) { 
                foundOption = newTypes.find(e => parseFloat(sortMoneyOrder(e.name)) == 3200);
            }

            if(foundOption !== null){
                SelectDonationLevel(foundOption);
            }

            // This parses the money out of the name of the donation and sorts
            newTypes = newTypes.sort((a,b) => {
                const amountA = parseFloat(sortMoneyOrder(a.name));
                const amountB = parseFloat(sortMoneyOrder(b.name));
                return amountA - amountB;
            });

            setOptions(newTypes);
        }
    }, [products])
  
    /// This takes the input string from stripe and gets the money amount
    function extractMoneyAmount(inputString) {
        if(inputString != null){
            const moneyAmountPattern = /\$([0-9,]+)/;
            const match = inputString.match(moneyAmountPattern);
            if (match) {
            const moneyAmount = match[1];
            return `$${moneyAmount}`;
            } else {
            return null;
            }
        }
    }

    /// This takes the input string from stripe and gets the name
    function extractPerkName(inputString) {
        const parts = inputString.split(",");
        if (parts.length > 0) return parts[0].trim();
    }

    async function LoadProducts(){
        let data = await fetchProducts();
        setProducts(data.data);
    }

    // This sorts the options based on amount of donation
    function sortMoneyOrder(inputString) {
        const moneyAmountPattern = /\$([0-9,]+)/;
        const match = inputString.match(moneyAmountPattern);
        if (match) {
        const moneyAmountWithCommas = match[1];
        const moneyAmount = moneyAmountWithCommas.replace(/,/g, "");

        return moneyAmount;
        } else {
        return null;
        }
    }

    function SelectDonationLevel(level){
        customer.selectedProduct = level;
        customer.selected_product_id = level.id;
        customer.selected_price_id = level.defaultPriceId;
        setPrimaryLevel(level);
        setSelectedProductName(level.name);
        updateContextValue(customer);
        setSelectedLevel(level.id);
        setFeatures(level.features);

        // Setting the value amount
        customer.valueAmount = parseInt(sortMoneyOrder(level.name));
    }

    function MouseOverLevel(level){
        setHoveredLevel(level);
        setFeatures(level.features);
    }

    function MouseLeaveLevel(){
        setHoveredLevel(0);
        setFeatures(primaryLevel.features);
    }

    function ChooseClass(level){
        let className = '';
        switch(type){
            case amiType:
                className = `buttonAmi grow px-12 max-w-[18rem] my-[0.5rem] mr-[3rem] w-full py-6 text-center rounded-lg border-[#0082CA] border-[1px] hover:bg-[#00CFF5] hover:text-[#FAFAFA] active:bg:black ${level.id === selectedLevel ? 'font-bold text-[#FAFAFA] border-[#0082CA] border-[1px]  bg-[#0082CA]' : '' }`;
                break;
            case leauxalType:
                className = `buttonLeauxcal grow px-12 max-w-[18rem] my-[0.5rem] mr-[3rem] w-full py-6 text-center rounded-lg border-[#5F259F] border-[1px] hover:bg-[#CD00E1] hover:text-[#FAFAFA] ${level.id === selectedLevel ? 'font-bold text-[#FAFAFA] bg-[#5F259F]' : '' }`;
                break;
            case rainAngelType:
                className = `buttonRainAngel grow px-12 max-w-[18rem] my-[0.5rem] mr-[3rem] w-full py-6 text-center rounded-lg border-black border-[1px] hover:bg-[#4F4D52] hover:text-[#FAFAFA] ${level.id === selectedLevel ? 'font-bold text-[#FAFAFA] bg-black text-white' : '' }`;
                break
            default:
                className = '';
                break;
        }

        return className;
    }


    return (
    <>
        <Box sx={{flexGrow: 1}}>        
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="border-[1px] border-[#080708]" />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="text-[1.5rem] font-bold m-[2rem] px-[4rem]">
                        Choose A Donation Level
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    {
                        options === null ? <></> :
                        options.map((level)=>
                                <button 
                                    key={level.id} 
                                    onClick={() => SelectDonationLevel(level)}
                                    onMouseOver={() => MouseOverLevel(level)}
                                    onMouseLeave={() => MouseLeaveLevel()}
                                    className={ChooseClass(level)} >
                                    <div>
                                        <div>{extractMoneyAmount(level.name)}</div>
                                        <div>{extractPerkName(level.name)}</div>
                                    </div>
                                </button> 
                        )
                    }
                </Grid>
                <Grid item xs={6}>
                    <Fragment>                            
                        <h3 className="font-bold mt-[2rem] ml-6">
                            {frequency} {extractMoneyAmount(selectedProductName)} Perks
                        </h3>
                        <div className="p-6 m-6 border-2 border-black rounded-lg">
                            {
                            features.map((feature, index) =>
                                <div key={index} className="ml-6 pt-2">
                                    <span>{feature.name}</span>
                                </div>)
                            }
                        </div>
                    </Fragment>                            
                </Grid>
            </Grid>
        </Box>
    </>
    );
}