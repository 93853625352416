import Config from "../config";
export default async function fetchCreatePaymentIntent(customer){
    let data = JSON.stringify({
        ...customer//,
        /*
        metadata: {
          //selectedProduct: customer.selectedProduct,
        },
        */
      });

    //const response = await fetch(Config.api_url + "/stripe/setup", {
    //const response = await fetch(Config.api_url + "/stripe/payment-intent", {
    const response = await fetch(Config.api_url + "/stripe/payment-intent-no-invoice", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const subscription = await response.json();
    return subscription;
}