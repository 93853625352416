/*module.exports = {
  stripe_publishable_key: "pk_test_QOax0YXWQ9XtfIkpGjBi9itp",
  api_url: "https://q4k021eh97.execute-api.us-east-1.amazonaws.com/production",
  web_url: "https://dev.d25o1ck80mssjq.amplifyapp.com",
};
*/
module.exports = {
  //api_url: "https://shane.ngrok.io/stripereporting",
  //api_url: "https:/bfdf22635399.ngrok.app/stripeReporting",

  // PROD
  api_url: "https://q4k021eh97.execute-api.us-east-1.amazonaws.com/production/stripereporting",
  stripe_publishable_key: "pk_live_l03HCWe1v74N6V51Nl28jkIP",

  // TEST
  /*
  api_url: "https:/localhost:7229/stripereporting",
  stripe_publishable_key: "pk_test_QOax0YXWQ9XtfIkpGjBi9itp",
  */

  web_url: "https://www.festivalinternational.org/donation-app-thank-you",


};
