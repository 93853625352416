import React from "react";
import Header from "./header";
import Frequency from "../views/frequency";
import ContactInfo from "../views/contactInfo";
import AdditionalInfo from "../views/additionalInfo";
import PaymentInfo from "../views/paymentInfo";
import { useEffect, useState } from "react";
import PaymentConfirmation from "../views/paymentconfirmation";

export default function DonationManager({displayName, type}) {

    const [view, setView] = useState(0);

    function Next(){
      setView(view+1);
    }

    function Previous(){
      setView(view-1);
    }

  return (
    <>
      <div className="ReactModalPortal">
        
        {view === 0 && (
          <>
            <Header type={type} displayName={displayName} />
            <Frequency type={type} displayName={displayName} Next={Next} />
          </>
        )}
        {view === 1 && 
          <>
          <Header type={type} displayName={displayName} />
          <ContactInfo displayName={displayName} Next={Next} Previous={Previous} />
          </>
        }
        {view === 2 && 
          <>
          <Header type={type} displayName={displayName} />
          <AdditionalInfo type={type} displayName={displayName}  Next={Next} Previous={Previous}/>
          </>
        }
        {view === 3 && 
          <>
          <Header type={type} displayName={displayName} />
          <PaymentInfo displayName={displayName} Next={Next}  Previous={Previous}/>
          </>
        }
        {view === 4 && 
          <>
            <PaymentConfirmation></PaymentConfirmation>
          </>
        }
      </div>
    </>
  );
}


