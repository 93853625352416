import React, {useContext, useEffect, useState} from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from "../components/button";
import CustomerContext from "../stores/customerContext";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { CircularProgress } from "@mui/material";
import fetchCreatePaymentIntent from "../services/fetchCreatePaymentIntent";
import YesNoOptions from "../stores/yesnooptions";
import DonationTypes from "../stores/donationTypes";
import DonationFrequencies from "../stores/donationFrequencies";
import fetchCreatePaymentIntentRecurring from "../services/fetchCreatePaymentIntentRecurring";

export default function AdditionalInfo({type, displayName, Next, Previous}) {
    const {customer, updateContextValue} = useContext(CustomerContext);
    const {one_time, monthly, annually} = DonationFrequencies();

    const sourceOptions = [
    "Facebook",
    "Instagram",
    "Twitter",
    "LinkedIn",
    "YouTube",
    "Google",
    "Past Ami",
    "Other",
    ];
    const tshirtSizes = ["S","M", "L", "XL", "XXL"];
    const {amiType, leauxalType, rainAngelType} = DonationTypes();
    const {yes, no, yesNoOptions} = YesNoOptions();

    const [name, setName] = useState(customer.recognized_name || '');
    const [heardFrom, setHeardFrom] = useState(customer.heard_from || '');
    const [dob, setDOB] = useState(customer.dob || '');
    const [referredBy, setReferredBy] = useState(customer.referred_by || '');
    const [wantedPerks, setWantedPerks] = useState(customer.wanted_perks === true ? yes : customer.wanted_perks === false ? no : '');
    const [shippedPerks, setShippedPerks] = useState(customer.shipped_perks === true ? yes : customer.shippedPerks === false ? no : '');
    const [shirtSize, setShirtSize] = useState(customer.shirt_size || '');
    const [serviceFee, setServiceFee] = useState(customer.cover_processing_fee === true ? yes : customer.cover_processing_fee === false ? no : null);

    // This is so we can set the proper spacing on the label for the select.
    const [sourceLabelText, setSourceLabelText] = useState(`Where did you hear about being a ${displayName}`);
    const [shirtSizeLabelText, setShirtSizeLabelText] = useState(`${displayName} T-Shirt Size`);

    const [canShowWantPerks, setCanShowWantPerks] = useState(type === amiType);
    const [canShowShippedPerks, setCanShowShippedPerks] = useState(wantedPerks === yes ? true : false);
    //const [canShowTShirt, setCanShowTShirt] = useState(wantedPerks === yes && type == amiType && customer.valueAmount > 50 || false);
    const [canShowTShirt, setCanShowTShirt] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [processingFeeValid, setProcessingFeeValid] = useState(true);
    const [perksValid, setPerksValid] = useState(true);


    useEffect(() => {
        if(wantedPerks != null){
            setCanShowShippedPerks(wantedPerks === yes && type === amiType);
        }

        if(wantedPerks === yes && type == amiType && customer.valueAmount > 140 ) {
            setCanShowTShirt(true);
        }

        if(customer != null){
            if(customer.selectedProduct != null){
                if(customer.selectedProduct.features != null && customer.selectedProduct.features.length > 0){
                    let foundTShirtFeature = customer.selectedProduct.features.find(o => o.name == 'Exclusive Ami T-Shirt');
                    if(foundTShirtFeature != null){
                        setCanShowTShirt(true);
                    }
                }
            }
        }        

    }, [wantedPerks]);

    function ChangeRecognizedName(e){
        setName(e.target.value);
        // Old
        //customer.recognizedName = e.target.value;
        // New
        customer.recognized_name = e.target.value;
        updateContextValue(customer);
    }

    function ChangeHeardFrom(e){
        setHeardFrom(e.target.value);
        // NEW
        customer.heard_from = e.target.value;
        //OLD
        //customer.heardFrom = e.target.value;
        updateContextValue(customer);
    }

    function ChangeDOB(e){
        setDOB(e);
        customer.dob = e;
        updateContextValue(customer);
    }

    function ChangeReferredBy(e){
        setReferredBy(e.target.value);
        // Old
        //customer.referredBy = e.target.value;
        // New
        customer.referred_by = e.target.value;
        updateContextValue(customer);
    }

    function ChangeWantedPerks(e){
        setWantedPerks(e.target.value);
        // Old
        //customer.wantedPerks = e.target.value;
        // New
        customer.wanted_perks = e.target.value === yes ? true : false;
        updateContextValue(customer);
    }

    function ChangeShippedPerks(e){
        setShippedPerks(e.target.value);
        // Old
        //customer.shippedPerks = e.target.value;
        // New
        customer.shipped_perks = e.target.value === yes ? true : false;

        updateContextValue(customer);
    }

    function ChangeShirtSize(e){
        setShirtSize(e.target.value);
        // Old
        //customer.shirtSize = e.target.value;
        // New
        customer.shirt_size = e.target.value;
        updateContextValue(customer);
    }

    function ChangeServiceFee(e){
        setServiceFee(e.target.value);
        // Old version
        //customer.serviceFee = e.target.value;
        // New version
        customer.cover_processing_fee = e.target.value === yes ? true : false;
        updateContextValue(customer);
    }

    /// This function is called so we can create the setup for stripe before going to the payment 
    async function HandleNext(cval){
        let isValid = true;
        if(type === amiType){
            if(wantedPerks === null || wantedPerks === ""){
                isValid = false;
                setPerksValid(false);
            }
            if(serviceFee === null || serviceFee === ""){
                isValid = false;
                setProcessingFeeValid(false);
            }
        }

        if(isValid){
            setIsLoading(true);
            if(customer.frequency === monthly || customer.frequency === annually){
                customer.subscription = await fetchCreatePaymentIntentRecurring(cval);
            }
            else{
                customer.subscription = await fetchCreatePaymentIntent(cval);
            }
            updateContextValue(customer);
            Next();
        }
    }

    return(
        <>
        <Box sx={{flexGrow: 1}}>
            <Grid container>
                <Grid item xs={12}>
                    <h2 className="font-bold text-xl my-6 pb-3 border-b border-black">
                        Additional Information
                    </h2>
                </Grid>
            </Grid>
        {
            isLoading === true ? 
                <Grid container className="place-content-center w-full h-screen">
                    <CircularProgress></CircularProgress>
                </Grid>
            :
            <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="grid my-6">
                        <TextField
                        id="recognizedName"
                        name="recognizedName"
                        label="Name/Company to be recognized"
                        variant="outlined"
                        value={name}
                        onChange={ChangeRecognizedName}
                        />
                    </div>

                    <div className="grid my-6">
                        <FormControl >
                            <InputLabel 
                            id="heardFrom"
                            sx={{
                                whiteSpace: 'normal'
                            }}>
                                Where did you hear about being a {displayName}?
                            </InputLabel>
                            <Select
                                labelId="heardFrom"
                                name="heardFrom"
                                label={sourceLabelText}
                                placeholder="Where did you hear about being a donor?"
                                value={heardFrom}
                                onChange={ChangeHeardFrom}
                                className="pt-4 md:pt-0"
                            >
                                {sourceOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className="grid my-6">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                            label="Date of Birth"
                            inputFormat="MM/DD/YYYY"
                            value={dob}
                            defaultValue={""}
                            onChange={ChangeDOB}
                            renderInput={(params) => (
                                <TextField
                                placeholder="Date of Birth"
                                name="dob"
                                {...params}
                                value={dob}
                                />
                            )}
                            />
                        </LocalizationProvider>
                    </div>

                    <div className="grid my-6">
                        <TextField
                            id="referredBy"
                            label="Referred by"
                            variant="outlined"
                            value={referredBy}
                            onChange={ChangeReferredBy}
                        />
                    </div>

                    <div className="grid my-6">
                        <FormControl >
                            <InputLabel 
                                id="perks-select-label"
                                sx={{
                                    whiteSpace: 'normal'
                                }}>
                                Would you like to further support Festival International by covering the processing fee (3%)?
                            </InputLabel>
                            <Select
                                id="serviceFee"
                                name="serviceFee"
                                labelId="fee-select-label"
                                label="Would you like to further support Festival International by covering the processing fee (3%)?"
                                placeholder="Would you like to further support Festival International by covering the processing fee (3%)?"
                                value={serviceFee}
                                onChange={ChangeServiceFee}
                                className="pt-4 md:pt-0"
                                error={!processingFeeValid}
                            >
                                {yesNoOptions.map((choice) => (
                                <MenuItem key={choice} value={choice}>
                                    {choice}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <div className="grid my-6">
                        {
                            canShowWantPerks === true ?
                        <FormControl >
                            <InputLabel id="perks-select-label">
                                Would you like to receive your Ami perks?
                            </InputLabel>
                            <Select
                                id="wantedPerks"
                                name="wantedPerks"
                                labelId="state-select-label"
                                label="Would you like to receive your Ami perks?"
                                placeholder="Would you like to receive your Ami perks?"
                                value={wantedPerks}
                                onChange={ChangeWantedPerks}
                                error={!perksValid}
                            >
                                {yesNoOptions.map((choice) => (
                                <MenuItem key={choice} value={choice}>
                                    {choice}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl> : <></>
                        }
                    </div>

                    <div className="grid my-6">
                        { canShowShippedPerks ? 
                        <FormControl>
                            <InputLabel id="shipping-select-label">
                            Would you like your perks shipped to you for an additional $5?
                            </InputLabel>
                            <Select
                            id="shippedPerks"
                            name="shippedPerks"
                            labelId="shipping-select-label"
                            label="Would you like your perks shipped to you for an additional $5?"
                            placeholder="Would you like your perks shipped to you for an additional $5?"
                            value={shippedPerks}
                            onChange={ChangeShippedPerks}
                            >
                            {yesNoOptions.map((choice) => (
                                <MenuItem key={choice} value={choice}>
                                {choice}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl> : <></>
                        }
                    </div>

                    <div className="grid my-6">
                    { canShowTShirt ?
                        <FormControl >
                            <InputLabel id="shirt-select-label">
                            {displayName} T-Shirt Size
                            </InputLabel>
                            <Select
                            labelId="shirtSize"
                            name="shirtSize"
                            label={shirtSizeLabelText}
                            placeholder="Select One"
                            value={shirtSize}
                            onChange={ChangeShirtSize}
                            >
                            {tshirtSizes.map((option) => (
                                <MenuItem key={option} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl> : <></>
                    }
                    </div>
            </div>
            <Grid container>
                <Grid item xs={6}>
                    <Button text={"Back"} displayName={displayName} onClick={Previous} />
                </Grid>
                <Grid item xs={6}>
                    <Button text={"Continue"} displayName={displayName} onClick={() => HandleNext(customer)} />
                </Grid>
            </Grid>
            </>
            }
        </Box>
        </>
      );

}
      