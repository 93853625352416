import classNames from "classnames";
import React from "react";

function Header(props) {
  return (
    <div
      className={classNames(" p-2 flex justify-between items-center", {
        "bg-[#0082CA]": props.displayName === "Amis du Festival",
        "bg-[#6f42c1]": props.displayName === "Festival Leauxcals",
        "bg-black": !(
          props.displayName === "Amis du Festival" ||
          props.displayName === "Festival Leauxcals"
        ),
      })}
    >
      <p className="text-white text-lg">{props.displayName}</p>
      <img className="logo" src="/logo_white.png" alt="Festival International" />
    </div>
  );
}

export default Header;
