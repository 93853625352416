import classNames from "classnames";
import React from "react";

function button(props) {
  const displayName = props.displayName;
  const onClick = props.onClick;
  return (
    <div
      className={classNames(
        "rounded-xl px-10 mx-3 py-4 flex items-center justify-center text-[#FAFAFA]",
        {
          "buttonAmi border-[#0082CA] border-[1px] bg-[#0082CA]":
            displayName === "Amis du Festival",
          "buttonLeauxcal border-[#5F259F] border-[1px] bg-[#5F259F] ":
            displayName === "Festival Leauxcals",
          "buttonRainAngel border-black border-[1px] bg-black":
            displayName === "Rain Angels' Circle" ||
            displayName === null ||
            displayName === undefined,
        }
      )}
      onClick={onClick}
    >
      {props.text}
    </div>
  );
}

export default button;
