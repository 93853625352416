import React, { useEffect, useState, useContext } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from "../components/button";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Config from "../config";
import CustomerContext from "../stores/customerContext";
import CheckoutForm from "../components/checkoutForm";
import YesNoOptions from "../stores/yesnooptions";
import DonationFrequencies from "../stores/donationFrequencies";
import fetchCancelPaymentIntent from "../services/fetchCancelPaymentIntent";

const stripePromise = loadStripe(Config.stripe_publishable_key);

export default function PaymentInfo({type, displayName, Previous, Next}) {
    const {customer, updateContextValue} = useContext(CustomerContext)
    const {yes, no, yesNoOptions} = YesNoOptions();
    const {one_time, monthly, annually} = DonationFrequencies();
    const [priceData, setPriceData] = useState(null);
    const [processingFee, setProcessingFee] = useState(null);
    const [shippingFee, setShippingFee] = useState(null);
    const [total, setTotal] = useState(null);

    useEffect(() =>{
        if(customer != null){
            let totalFee = customer.valueAmount;
            setPriceData(`\$${totalFee.toFixed(2)}`);
            totalFee += DetermineProcessingFee(customer);
            totalFee += DetermineShippingFee(customer);
            setTotal(`\$${totalFee.toFixed(2)}`);
        }
    }, [customer]);

    function DetermineProcessingFee(customer){
        let fee = 0;
        if(customer.cover_processing_fee === true){
            fee = customer.valueAmount * .03;
            setProcessingFee(`\$${fee.toFixed(2)}`);
        }
        else{
            setProcessingFee("No processing fee.");
        }

        return fee;
    }

    function DetermineShippingFee(customer){
        let fee = 0;
        if(customer.wanted_perks === false){
            setShippingFee("No shipping needed. I do not want my perks.");
        }
        else if(customer.wanted_perks === true && customer.shipped_perks !== true){
            setShippingFee(`No Shipping Needed. I will pick up my ${displayName} perks.`);
        }
        else if(customer.wanted_perks === true && customer.shipped_perks === true && customer.frequency === one_time){
            setShippingFee("$5");
            fee = 5;
        }
        else if(customer.wanted_perks === true && customer.shipped_perks === true){
            setShippingFee("$5.15");
            fee = 5.15;
        }
        else if(customer.wanted_perks === false){
            setShippingFee("No Shipping Needed, I do not want my perks.");
        }
        return fee;
    }

    const currencyFormat = (num) => {
        return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    };

    function HandleBackCall(){
        fetchCancelPaymentIntent(customer.subscription.SubscriptionId);
        Previous();
    }

    function PaymentSuccessful(){
        Next();
    }

    return(
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <h2 className="font-bold text-xl my-6 pb-3 border-b border-black">
                        Payment Information
                    </h2>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="grid my-6">
                                <div className="font-bold">Item</div>
                                {customer.selectedProduct.name}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="grid my-6">
                                <div className="font-bold">Price</div>
                                { priceData }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="grid my-6">
                                <div className="font-bold">Processing Fee</div>
                                { processingFee }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="grid my-6">
                                <div className="font-bold">Shipping Fee</div>
                                { shippingFee }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="grid my-6">
                                <div className="font-bold">Total</div>
                                { total }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Elements
                        stripe={stripePromise}
                        options={{clientSecret: customer.subscription.clientSecret}}>
                            <CheckoutForm displayName={displayName} PaymentSuccessful={PaymentSuccessful}></CheckoutForm>
                        </Elements>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Button text={"Back"} displayName={displayName} onClick={HandleBackCall} />
                </Grid>
                <Grid item xs={6}>
                </Grid>
            </Grid>
        </Box>
      );
}
      