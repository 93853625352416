import React, {Fragment, useState, useContext, useEffect} from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustomerContext from "../stores/customerContext";
import Button from "../components/button";

var UsaStates = require("usa-states").UsaStates;
var usStates = new UsaStates();

export default function ContactInfo({displayName, Next, Previous}){
    const individualChoice ="Individual"; 
    const businessChoice = "Business"; 

    const {customer, updateContextValue} = useContext(CustomerContext);
    const Types = [{ name: individualChoice }, { name: businessChoice }];
    const [organization, setOrganization] = useState(customer.individual === true ? individualChoice : customer.individual === false ? businessChoice : null);
    const [showBName, setShowBName] = useState(customer.individual === false);

    const [bName, setBName] = useState(customer.business_name);
    const [firstName, setFirstName] = useState(customer.first_name);
    const [lastName, setLastName] = useState(customer.last_name);
    const [email, setEmail] = useState(customer.email);
    const [phoneNumber, setPhoneNumber] = useState(customer.phone);
    const [address1, setAddress1] = useState(customer.address_line1);
    const [address2, setAddress2] = useState(customer.address_line2);
    const [usState, setUSState] = useState(customer.address_state);
    const [city, setCity] = useState(customer.address_city);
    const [zip, setZip] = useState(customer.address_zip);

    const [busNameValid, setBusNameValid] = useState(true);
    const [firstNameValid, setFirstNameValid] = useState(true);
    const [lastNameValid, setLastNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    function ChangeOrganization(e){
        setOrganization(e.target.value);
        customer.individual = e.target.value === individualChoice ? true : false;
        if(e.target.value === businessChoice){
            setShowBName(true);
        }
        else{
            setShowBName(false);
        }

        updateContextValue(customer);
    }

    function NextClick(){
        let isValid = true;
        if(firstName === null || firstName === '' || firstName === undefined){
            setFirstNameValid(false);
            isValid = false;
        }
        if(lastName === null || lastName === '' || lastName === undefined){
            setLastNameValid(false);
            isValid = false;
        }
        if(email === null || email === '' || email === undefined){
            setEmailValid(false);
            isValid = false;
        }
        if(organization === businessChoice && (bName === undefined || bName === null || bName === '')){
            setBusNameValid(false);
            isValid = false;
        }

        if(isValid){
            Next();
        }
    }

    function ChangeBusinessName(e){
        setBName(e.target.value);
        customer.business_name = e.target.value;
        updateContextValue(customer);
    }

    function ChangeFirstName(e){
        setFirstName(e.target.value);
        customer.first_name = e.target.value;
        updateContextValue(customer);
    }

    function ChangeLastName(e){
        setLastName(e.target.value);
        customer.last_name = e.target.value;
        updateContextValue(customer);
    }

    function ChangeEmail(e){
        setEmail(e.target.value);
        customer.email = e.target.value;
        updateContextValue(customer);
    }

    function ChangePhoneNumber(e){
        setPhoneNumber(e.target.value);
        customer.phone = e.target.value;
        updateContextValue(customer);
    }

    function ChangeAddress1(e){
        setAddress1(e.target.value);
        customer.address_line1 = e.target.value;
        updateContextValue(customer);
    }

    function ChangeAddress2(e){
        setAddress2(e.target.value);
        customer.address_line2 = e.target.value;
        updateContextValue(customer);
    }

    function ChangeCity(e){
        setCity(e.target.value);
        customer.address_city = e.target.value;
        updateContextValue(customer);
    }

    function ChangeState(e){
        setUSState(e.target.value);
        customer.address_state = e.target.value;
        updateContextValue(customer);
    }

    function ChangeZip(e){
        setZip(e.target.value);
        customer.address_zip = e.target.value;
        updateContextValue(customer);
    }

    return(
        <Fragment>
            <Box sx={{flexGrow: 4}}>
                <Grid container>
                    <Grid item xs={12}>
                        <h2 className="font-bold text-xl grid my-6 pb-3 border-b border-black">
                            Contact Information
                        </h2>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="grid my-6">
                            <FormControl fullWidth>
                                <InputLabel id="organization-select-label">Organization Type</InputLabel>
                                <Select
                                id="organization"
                                name="organization"
                                labelId="state-organization-label"
                                label="Select Organization Type"
                                placeholder="Select Organization Type"
                                value={organization}
                                onChange={ChangeOrganization}
                                >
                                {Types.map((state) => (
                                    <MenuItem key={state.name} value={state.name}>
                                    {state.name}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            { showBName === true ?
                                <div className="mt-[1.5rem]">
                                    <TextField
                                        required
                                        error={!busNameValid}
                                        id="business_name"
                                        name="business_name"
                                        label="Business Name"
                                        variant="outlined"
                                        value={bName}
                                        onChange={ChangeBusinessName}
                                        style={{ width: "100%" }} />
                                </div> : <></>
                            }
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div className="grid my-6 mr-3">
                            <TextField
                                required
                                error={!firstNameValid}
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                variant="outlined"
                                onChange={ChangeFirstName}
                                value={firstName}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="grid my-6 ml-3">
                            <TextField
                                error={!lastNameValid}
                                required
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                variant="outlined"
                                onChange={ChangeLastName}
                                value={lastName}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <div className="grid my-6 mr-3">
                            <TextField
                                error={!emailValid}
                                required
                                id="email"
                                name="email"
                                label="Email"
                                variant="outlined"
                                onChange={ChangeEmail}
                                value={email}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="grid my-6 ml-3">
                            <TextField
                                id="phonenumber"
                                name="phonenumber"
                                label="Phone Number"
                                variant="outlined"
                                onChange={ChangePhoneNumber}
                                value={phoneNumber}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="grid my-6">
                            <TextField
                                id="address1"
                                name="address1"
                                label="Address Line 1"
                                variant="outlined"
                                onChange={ChangeAddress1}
                                value={address1}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="grid my-6">
                            <TextField
                                id="address2"
                                name="address2"
                                label="Address Line 2"
                                variant="outlined"
                                onChange={ChangeAddress2}
                                value={address2}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4}>
                        <div className="grid my-6 mr-2">
                            <FormControl fullWidth>
                                <InputLabel id="state-select-label">State</InputLabel>
                                <Select
                                id="address_state"
                                name="address_state"
                                labelId="state-select-label"
                                label="Select State"
                                placeholder="Select State"
                                value={usState}
                                onChange={ChangeState}
                                >
                                {usStates.states.map((state) => (
                                    <MenuItem key={state.name} value={state.name}>
                                    {state.name}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="grid my-6 mx-2">
                            <TextField
                                id="city"
                                name="city"
                                label="City"
                                variant="outlined"
                                onChange={ChangeCity}
                                value={city}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className="grid my-6 ml-2">
                            <TextField
                                id="zip"
                                name="zip"
                                label="Zip"
                                variant="outlined"
                                onChange={ChangeZip}
                                value={zip}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6}>
                        <Button text={"Back"} displayName={displayName} onClick={Previous} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button text={"Continue"} displayName={displayName} onClick={NextClick} />
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
}