import React, {useContext, useState, useEffect} from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from "../components/button";
import CustomerContext from "../stores/customerContext";

export default function PaymentConfirmation({displayName}) {

    return (
        <>
        <div>
            <img className="logo" src="/FIL Horizontal Logo_WEB.jpg" alt="Festival International" />
        </div>
        <p>
            Thank you! Thank you for your generous donation! Your support helps us continue our mission of bringing unity, culture, and community to Lafayette year after year. YOU make Festival possible. Thank you again for your support.
        </p>
        </>
    );
}
